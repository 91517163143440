<div mat-subheader *ngIf="item.navCap" class="nav-caption">
  {{ item.navCap }}
</div>
<a mat-list-item (click)="onItemSelected(item)" [ngClass]="{
    'mat-toolbar mat-primary activeMenu': item.route
      ? router.isActive(item.route, true)
      : false,
    expanded: expanded,
    disabled: item.disabled
  }" class="menu-list-item" *ngIf="!item.navCap && !item.external && !item.twoLines">
  <i-tabler class="routeIcon" name="{{ item.iconName }}" matListItemIcon></i-tabler>
  <span class="hide-menu">{{ item.displayName | translate }}</span>
  <span class="arrow-icon" fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
  <span *ngIf="item.chip">
    <span class="{{ item.chipClass }} p-x-8 p-y-4 item-chip f-w-500 rounded-pill ">{{ item.chipContent }}</span>
  </span>
</a>

<!-- external Link -->
<mat-list-item onClick="window.open('//google.com')" class="menu-list-item" *ngIf="!item.navCap && item.external"
  target="_blank">
  <i-tabler class="routeIcon" name="{{ item.iconName }}" matListItemIcon></i-tabler>
  <span class="hide-menu">{{ item.displayName | translate }}</span>
</mat-list-item>

<!-- children -->
<div *ngIf="expanded">
  <app-nav-item *ngFor="let child of item.children" [item]="child" (click)="onSubItemSelected(child)"
    [depth]="depth + 1">
  </app-nav-item>
</div>