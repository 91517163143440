import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../services/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private loginService: LoginService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([403].indexOf(err.status) !== -1) {
          this.router.navigate(['/unauthorized']);
        }
        if ([401].indexOf(err.status) !== -1) {
          this.loginService.logout();
          this.router.navigate(['/authentication/login']);
        }
        const error = err || err.statusText;
        return throwError(() => error);
      })
    );
  }
}
