import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../../user/models/user';
import { CoreService } from 'src/app/services/core.service';
import { getUserLocationDescription } from 'src/app/custom-common/utils/functions';
import { Organization } from '../../organization/models/organization';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private options = this.settings.getOptions();

  private currentUserSubject: BehaviorSubject<any>;
  private currentUserTokenSubject: BehaviorSubject<any>;
  private currentOrganizationSubject: BehaviorSubject<any>;
  private userDataLocalStorageKey;
  currentUser$: Observable<User>;
  currentUserToken$: Observable<string>;
  currentOrganization$: Observable<Organization>;
  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;

  constructor(
    private httpClient: HttpClient,
    private settings: CoreService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentUserTokenSubject = new BehaviorSubject<any>(null);
    this.currentUserToken$ = this.currentUserTokenSubject.asObservable();
    this.currentOrganizationSubject = new BehaviorSubject<any>(null);
    this.currentOrganization$ = this.currentOrganizationSubject.asObservable();
    this.isLoggedIn$ = this.currentUser$.pipe(map(user => !!user));
    this.isLoggedOut$ = this.isLoggedIn$.pipe(map(isLoggedIn => !isLoggedIn));
    this.userDataLocalStorageKey = this.options.userDataLocalStorageKey;
    const userData = localStorage.getItem(this.userDataLocalStorageKey);

    if (userData) {
      this.currentUserSubject.next(JSON.parse(userData).user);
      this.currentUserTokenSubject.next(JSON.parse(userData).token);
      this.currentOrganizationSubject.next(JSON.parse(userData).user.organization);
    }
  }

  public get currentUser(): User {
    return this.currentUserSubject.value;
  }

  public get token(): string {
    return this.currentUserTokenSubject.value;
  }

  public get organization(): Organization {
    return this.currentOrganizationSubject.value;
  }

  updateCurrentUserValue(user: User) {
    this.currentUserSubject.next(user);
    const userData = JSON.parse(localStorage.getItem(this.userDataLocalStorageKey) as string);
    const updatedUserData = { ...userData, user };
    localStorage.setItem(this.userDataLocalStorageKey, JSON.stringify(updatedUserData));
  }

  updateCurrentOrganizationValue(user: User, organization: Organization) {
    this.currentUserSubject.next({ ...user, organization });
    this.currentOrganizationSubject.next(organization);
    const userData = JSON.parse(localStorage.getItem(this.userDataLocalStorageKey) as string);
    const updatedUserData = { ...userData, user: { ...user, organization } };
    localStorage.setItem(this.userDataLocalStorageKey, JSON.stringify(updatedUserData));
  }

  login(email: string, password: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
        switchMap((response) => {
          localStorage.setItem(this.userDataLocalStorageKey, JSON.stringify(response));

          const user = response.user;
          const token = response.token;
          const organization = response.user.organization;
          const location = getUserLocationDescription(response.location);

          this.currentUserSubject.next(user);
          this.currentUserTokenSubject.next(token);
          this.currentOrganizationSubject.next(organization);

          const responseObj = { user, location };
          return of(responseObj);
        })
      );
  }

  getEntityIdByRoleName(): string {
    const roleName = this.currentUser.role.name;
    if (roleName.includes('Super-Admin')) {
      return '';
    } else if (roleName.includes('Organization')) {
      return this.currentUser.organization._id;
    } else if (roleName.includes('Location')) {
      return this.currentUser.location._id;
    } else if (roleName.includes('Tenant') || roleName.includes('Employee')) {
      return this.currentUser.tenant._id;
    } else {
      throw new Error('Invalid role name');
    }
  }

  logout() {
    localStorage.removeItem(this.userDataLocalStorageKey);
    this.currentUserSubject.next(null);
  }
}