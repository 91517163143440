import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ServerMessage } from "../interfaces/server-message";


// Get error message from server
export function getErrorMessage(error: any): string {
    const errorMessage: ServerMessage = error.error;
    let message = '';
    if (errorMessage?.message) {
        message = errorMessage.message;
    } else {
        message = 'An error occurred';
    }
    return message;
}

// Get Server Success Message
export function getSuccessMessage(message: any): string {
    const successMessage: ServerMessage = message;
    let success = '';
    if (successMessage?.message) {
        success = successMessage.message;
    } else {
        success = 'Success';
    }
    return success;
}

// Generate a random 10 character complex password
export function generatePassword(): string {
    const length = 10;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0; i < length; i++) {
        retVal += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return retVal;
}

// Complex password validator
export function complexPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const password = control.value;
        if (!password) {
            return null;
        }

        const minLength = password.length >= 8;
        const hasUpperCase = /[A-Z]+/.test(password);
        const hasLowerCase = /[a-z]+/.test(password);
        const hasNumeric = /[0-9]+/.test(password);
        const hasSpecialChar = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(password);

        const isValid = minLength && hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar;

        return !isValid ? { complexPassword: true } : null;
    };
}

// Password match validator
export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    return password && confirmPassword && password.value !== confirmPassword.value
        ? { mismatch: true }
        : null;
};

// Url validator
export function urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const url = control.value;
        if (!url) {
            return null;
        }

        const urlPattern = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        const isValid = urlPattern.test(url);

        return !isValid ? { invalidUrl: true } : null;
    };
}

// Get User location description
export function getUserLocationDescription(location: any): string {
    if (location.status === 'fail') {
        return 'Unknown Location';
    } else {
        return `${location.city}, ${location.regionName}, ${location.country}`;
    }
}

// Get Date Time from ISO string
export function getDateTimeFromISO(isoString: string): { date: string, time: string } {
    const date = new Date(isoString);

    if (isNaN(date.getTime())) {
        throw new Error("Invalid ISO string");
    }

    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
    });

    return { date: formattedDate, time: formattedTime };
}



