import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { map } from 'rxjs/operators';
import { LoginService } from '../services/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private loginService: LoginService) { }

  canActivate() {
    return this.checkIfLogedIn();
  }

  private checkIfLogedIn() {
    return this.loginService.isLoggedIn$.pipe(
      map(isLogged =>
        isLogged ? true : this.router.parseUrl('/authentication/login')
      )
    );
  }
}
