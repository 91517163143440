<mat-sidenav-container class="mainWrapper" autosize autoFocus [ngClass]="{
    'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top',
    'sidebarNav-horizontal': options.horizontal,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme == 'orange_theme',
    blue_theme: options.activeTheme == 'blue_theme',
    aqua_theme: options.activeTheme == 'aqua_theme',
    purple_theme: options.activeTheme == 'purple_theme',
    green_theme: options.activeTheme == 'green_theme',
    cyan_theme: options.activeTheme == 'cyan_theme'
  }" [dir]="options.dir!">

  <!-- ============================================================== -->
  <!-- Vertical Sidebar -->
  <!-- ============================================================== -->
  <mat-sidenav #leftsidenav [mode]="isOver ? 'over' : 'side'" [opened]="
      options.navPos === 'side' &&
      options.sidenavOpened &&
      !isOver
    " *ngIf="!options.horizontal" (openedChange)="onSidenavOpenedChange($event)" (closedStart)="onSidenavClosedStart()"
    class="sidebarNav">
    <div class="flex-layout">
      <app-sidebar></app-sidebar>
      <ng-scrollbar class="position-relative" style="height: 100%">
        <mat-nav-list class="sidebar-list">
          <app-nav-item *ngFor="let item of navItems" [item]="item" (notify)="sidenav.toggle()">
          </app-nav-item>
        </mat-nav-list>
      </ng-scrollbar>
      <div class="p-24 m-t-auto profile-bar">
        <div class="bg-light-accent d-flex align-items-center rounded p-16">
          <img *ngIf="avatar; else avatarTemplate" [src]="avatar" class="rounded-circle object-cover" width="40"
            height="40" />
          <ng-template #avatarTemplate>
            <img [src]="avatarDefault" class="rounded-circle object-cover" width="40" height="40" />
          </ng-template>
          <div class="m-l-16">
            <h5 class="mat-subtitle-2 f-w-600">{{ user.firstName }}</h5>
            <span class="f-s-12"> {{ user.role.name }} </span>
          </div>
          <div class="m-l-auto">
            <a mat-icon-button (click)="logout()" class="d-flex justify-content-center">
              <i-tabler name="power" class="text-primary icon-18 d-flex"></i-tabler>
            </a>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <!-- ============================================================== -->
  <!-- horizontal Sidebar -->
  <!-- ============================================================== -->
  <mat-sidenav #leftsidenav [mode]="'over'" [opened]="options.sidenavOpened && !isTablet" *ngIf="resView"
    (openedChange)="onSidenavOpenedChange($event)" (closedStart)="onSidenavClosedStart()" class="sidebarNav">
    <app-sidebar> </app-sidebar>
    <ng-scrollbar class="position-relative" style="height: 100%">
      <mat-nav-list class="sidebar-list">
        <app-nav-item *ngFor="let item of navItems" [item]="item" (notify)="sidenav.toggle()">
        </app-nav-item>
      </mat-nav-list>
    </ng-scrollbar>
    <div class="p-24 m-t-auto profile-bar">
      <div class="bg-light-accent d-flex align-items-center rounded p-16">
        <img *ngIf="avatar; else avatarTemplate1" [src]="avatar" class="rounded-circle object-cover" width="40"
          height="40" />
        <ng-template #avatarTemplate1>
          <img [src]="avatarDefault" class="rounded-circle object-cover" width="40" height="40" />
        </ng-template>
        <div class="m-l-16">
          <h5 class="mat-subtitle-2 f-w-600"> {{ user.firstName }} </h5>
          <span class="f-s-12"> {{ user.role.name }} </span>
        </div>
        <div class="m-l-auto">
          <a mat-icon-button (click)="logout()" class="d-flex justify-content-center">
            <i-tabler name="power" class="text-primary icon-18 d-flex"></i-tabler>
          </a>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <!-- ============================================================== -->
  <!-- Main Content -->
  <!-- ============================================================== -->
  <mat-sidenav-content class="contentWrapper" #content>
    <!-- ============================================================== -->
    <!-- VerticalHeader -->
    <!-- ============================================================== -->
    <ng-container *ngIf="!options.horizontal; else horizontalheader">
      <app-header [showToggle]="!isOver" (toggleCollapsed)="toggleCollapsed()" (toggleMobileNav)="sidenav.toggle()"
        (toggleMobileFilterNav)="filterNavRight.toggle()">
      </app-header>
      <!-- <app-trial-banner></app-trial-banner> -->
    </ng-container>

    <!-- horizontal header -->
    <ng-template #horizontalheader>
      <app-horizontal-header (toggleMobileNav)="sidenav.toggle()" (toggleMobileFilterNav)="filterNavRight.toggle()">
      </app-horizontal-header>
      <app-trial-banner></app-trial-banner>
    </ng-template>

    <app-horizontal-sidebar *ngIf="options.horizontal"></app-horizontal-sidebar>

    <main class="pageWrapper" [ngClass]="{
        maxWidth: options.boxed
      }">
      <app-breadcrumb></app-breadcrumb>

      <!-- ============================================================== -->
      <!-- Outlet -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!--
        <div class="customizerBtn">
        <button mat-fab color="primary" (click)="customizerRight.toggle()">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
      -->
    </main>
  </mat-sidenav-content>

  <mat-sidenav #customizerRight mode="over" position="end">
    <app-customizer (optionsChange)="receiveOptions($event)"></app-customizer>
  </mat-sidenav>
</mat-sidenav-container>

<!-- ------------------------------------------------------------------
    Mobile Apps Sidebar
    ------------------------------------------------------------------ -->
<mat-drawer #filterNavRight mode="over" position="end" class="filter-sidebar">
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="branding">
        <a href="/" *ngIf="options.theme === 'light'">
          <img src="./assets/images/logos/dark-logo.svg" class="align-middle m-2" alt="logo" />
        </a>
        <a href="/" *ngIf="options.theme === 'dark'">
          <img src="./assets/images/logos/light-logo.svg" class="align-middle m-2" alt="logo" />
        </a>
      </div>
      <button mat-icon-button (click)="filterNavRight.toggle()" class="d-flex justify-content-center">
        <i-tabler name="x" class="icon-18 d-flex"></i-tabler>
      </button>
    </div>

    <mat-accordion>
      <mat-expansion-panel class="shadow-none">
        <mat-expansion-panel-header>
          <mat-panel-title class="f-s-16 f-w-500"> Apps </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div class="row">
            <div class="col-12 text-hover-primary" *ngFor="let appdd of apps">
              <a [routerLink]="[appdd.link]" class="d-flex align-items-center text-decoration-none m-b-24 gap-16">
                <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                  <img [src]="appdd.img" width="20" />
                </button>

                <div>
                  <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                    {{ appdd.title }}
                  </h5>
                  <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                </div>
              </a>
            </div>
          </div>
          <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">Quick Links</h4>
          <div class="text-hover-primary" *ngFor="let quicklink of quicklinks">
            <a [routerLink]="['quicklink.link']"
              class="hover-text text-decoration-none mat-body-1 f-w-600 d-block p-y-8">{{ quicklink.title }}</a>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="p-x-24">
      <a [routerLink]="['/apps/calendar']"
        class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8">
        <span class="f-s-15 f-w-500 m-l-8">Calendar</span>
      </a>
      <a [routerLink]="['/apps/chat']" class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16">
        <span class="f-s-15 f-w-500 m-l-8">Chat</span>
      </a>
      <a [routerLink]="['/apps/email/inbox']"
        class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8">
        <span class="f-s-15 f-w-500 m-l-8">Email</span>
      </a>
    </div>
  </div>
</mat-drawer>