import { NavItem } from '../../vertical/sidebar/nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Home'
  },
  {
    displayName: 'Dashboard',
    iconName: 'home',
    route: '/dashboard'
  },
  {
    navCap: 'Visitors'
  },
  {
    displayName: 'Check Visitor',
    iconName: 'door-enter',
    children: [
      {
        displayName: 'Check In/Out',
        iconName: 'point',
        route: '/log/log-list'
      },
      {
        displayName: 'History',
        iconName: 'point',
        route: '/log/history-list'
      },
      {
        displayName: 'Visit Purpose',
        iconName: 'point',
        route: '/log/visit-purpose-list'
      },
      {
        displayName: 'Devices',
        iconName: 'point',
        route: '/log/device-list'
      }
    ]
  },
  {
    displayName: 'Appointments',
    iconName: 'calendar',
    route: '/log/appointment-list'
  },
  {
    navCap: 'Locations'
  },
  {
    displayName: 'Sites',
    iconName: 'building-skyscraper',
    //route: '/menu-level',
    children: [
      {
        displayName: 'locations',
        iconName: 'point',
        route: '/location/location-list'
      },
      {
        displayName: 'Tenants',
        iconName: 'point',
        route: '/tenant/tenant-list'
      }
    ]
  },
  {
    displayName: 'Employees',
    iconName: 'users',
    route: '/employee/employee-list'
  },
  {
    displayName: 'Visitors',
    iconName: 'user-check',
    children: [
      {
        displayName: 'Visitors',
        iconName: 'point',
        route: '/visitor/visitor-list'
      },
      {
        displayName: 'Blacklist',
        iconName: 'point',
        route: '/visitor/visitor-blacklist'
      },
      {
        displayName: 'Visitor Types',
        iconName: 'point',
        route: '/visitor/visitor-type-list'
      }
    ]
  },
  {
    navCap: 'Management'
  },
  {
    displayName: 'Organization',
    iconName: 'briefcase',
    route: '/organization/organization-view/:id'
  },
  {
    displayName: 'Access',
    iconName: 'lock-access',
    //route: '/menu-level',
    children: [
      {
        displayName: 'Users',
        iconName: 'point',
        route: '/user/user-list'
      },
      {
        displayName: 'Roles',
        iconName: 'point',
        route: '/role/role-list'
      },
      {
        displayName: 'Logs',
        iconName: 'point',
        route: '/system-log/system-log-list'
      }
    ]
  },
  {
    navCap: 'Settings'
  },
  {
    displayName: 'Account',
    iconName: 'user-circle',
    route: '/setting/account'
  }
  /**
   * ,
  {
    navCap: 'Other',
  },
  {
    displayName: 'Disabled',
    iconName: 'ban',
    route: '/disabled',
    disabled: true,
  },
  {
    displayName: 'Chip',
    iconName: 'mood-smile',
    route: '/',
    chip: true,
    chipClass: 'bg-primary text-white',
    chipContent: '9',
  },
  {
    displayName: 'Outlined',
    iconName: 'mood-smile',
    route: '/',
    chip: true,
    chipClass: 'b-1 border-primary text-primary',
    chipContent: 'outlined',
  },
  {
    displayName: 'External Link',
    iconName: 'star',
    route: 'https://www.google.com/',
    external: true,
  }
   */
];
