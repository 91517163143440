import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Home'
  },
  {
    displayName: 'Dashboard',
    iconName: 'chart-infographic',
    route: '/dashboard'
  },
  {
    navCap: 'Visits'
  },
  {
    displayName: 'Visit Logs',
    iconName: 'door-enter',
    children: [
      {
        displayName: 'Check In/Out',
        iconName: 'point',
        route: '/log/log-list'
      },
      {
        displayName: 'History',
        iconName: 'point',
        route: '/log/history-list'
      }
    ]
  },
  {
    displayName: 'Appointments',
    iconName: 'calendar',
    route: '/log/appointment-list'
  },
  {
    displayName: 'Visitor Info',
    iconName: 'info-circle',
    children: [
      {
        displayName: 'Visitors',
        iconName: 'point',
        route: '/visitor/visitor-list'
      },
      {
        displayName: 'Blacklist',
        iconName: 'point',
        route: '/visitor/visitor-blacklist'
      }
    ]
  },
  {
    displayName: 'Hosts',
    iconName: 'user',
    route: '/log/host-list'
  },
  {
    displayName: 'Devices',
    iconName: 'devices',
    route: '/log/device-list'
  },
  {
    displayName: 'Settings',
    iconName: 'settings',
    children: [
      {
        displayName: 'Visit Purpose',
        iconName: 'point',
        route: '/log/visit-purpose-list'
      },
      {
        displayName: 'Visitor Types',
        iconName: 'point',
        route: '/visitor/visitor-type-list'
      }
    ]
  },
  {
    navCap: 'Sites'
  },
  {
    displayName: 'Locations',
    iconName: 'map-pin',
    route: '/location/location-list'
  },
  {
    displayName: 'Tenants',
    iconName: 'building',
    route: '/tenant/tenant-list'
  },
  // {
  //   displayName: 'Employees',
  //   iconName: 'users',
  //   route: '/employee/employee-list'
  // },
  {
    navCap: 'Management'
  },
  {
    displayName: 'Organization',
    iconName: 'briefcase',
    route: '/organization/organization-view/:id'
  },
  {
    displayName: 'Access',
    iconName: 'lock-access',
    children: [
      {
        displayName: 'Users',
        iconName: 'point',
        route: '/user/user-list'
      },
      {
        displayName: 'Roles',
        iconName: 'point',
        route: '/role/role-list'
      },
      {
        displayName: 'Logs',
        iconName: 'point',
        route: '/system-log/system-log-list'
      }
    ]
  },
  {
    navCap: 'Administration'
  },
  {
    displayName: 'Account',
    iconName: 'user-circle',
    route: '/setting/account'
  },
  {
    displayName: 'Billing',
    iconName: 'receipt',
    route: '/billing/billing'
  },
  /**
   * ,
  {
    navCap: 'Other',
  },
  {
    displayName: 'Disabled',
    iconName: 'ban',
    route: '/disabled',
    disabled: true,
  },
  {
    displayName: 'Chip',
    iconName: 'mood-smile',
    route: '/',
    chip: true,
    chipClass: 'bg-primary text-white',
    chipContent: '9',
  },
  {
    displayName: 'Outlined',
    iconName: 'mood-smile',
    route: '/',
    chip: true,
    chipClass: 'b-1 border-primary text-primary',
    chipContent: 'outlined',
  },
  {
    displayName: 'External Link',
    iconName: 'star',
    route: 'https://www.google.com/',
    external: true,
  }
   */
];
