import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './pages/authentication/helpers';
import { UnauthorisedComponent } from './pages/authentication/unauthorised/unauthorised.component';
import { SubscriptionGuard } from './guards/subscription-guard.service';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'log',
        loadChildren: () =>
          import('./pages/log/log.module').then((m) => m.LogModule),
        //canActivate: [AuthGuard, SubscriptionGuard]
        canActivate: [AuthGuard]
      },
      {
        path: 'location',
        loadChildren: () =>
          import('./pages/location/location.module').then((m) => m.LocationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tenant',
        loadChildren: () =>
          import('./pages/tenant/tenant.module').then((m) => m.TenantModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'employee',
        loadChildren: () =>
          import('./pages/employee/employee.module').then((m) => m.EmployeeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'visitor',
        loadChildren: () =>
          import('./pages/visitor/visitor.module').then((m) => m.VisitorModule),
        //canActivate: [AuthGuard, SubscriptionGuard]
        canActivate: [AuthGuard]
      },
      {
        path: 'organization',
        loadChildren: () =>
          import('./pages/organization/organization.module').then((m) => m.OrganizationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./pages/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'role',
        loadChildren: () =>
          import('./pages/role/role.module').then((m) => m.RoleModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'permission',
        loadChildren: () =>
          import('./pages/permission/permission.module').then((m) => m.PermissionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'system-log',
        loadChildren: () =>
          import('./pages/system-logs/system-logs.module').then((m) => m.SystemLogsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./pages/account/account.module').then((m) => m.AccountModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'maps',
        loadChildren: () =>
          import('./pages/maps/maps.module').then((m) => m.MapsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('./pages/billing/billing.module').then((m) => m.BillingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'unauthorized',
        component: UnauthorisedComponent,
        data: {
          title: 'Unauthorized'
        }
      }
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/error',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
