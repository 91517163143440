<div class="do-not-print">
  <mat-toolbar class="topbar">
    <!-- --------------------------------------------------------------- -->
    <!-- Desktop Menu -->
    <button mat-icon-button (click)="toggleCollapsed.emit()" *ngIf="showToggle" class="d-flex justify-content-center">
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>

    <!-- Mobile Menu -->
    <button mat-icon-button (click)="toggleMobileNav.emit()" *ngIf="!showToggle" class="d-flex justify-content-center">
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>
    <!-- --------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------- -->
    <!--  Search
      <button mat-icon-button (click)="openDialog()" class="d-flex justify-content-center">
      <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
    </button>
    -->
    <!-- --------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------- -->
    <!--  Links -->
    <!-- --------------------------------------------------------------- -->
    <div class="d-none d-lg-flex">
      <!--
        <button mat-button [matMenuTriggerFor]="appsmenu" aria-label="Notifications">
        <div class="d-flex align-items-center">
          Apps <i-tabler name="chevron-down" class="icon-16 m-l-4"></i-tabler>
        </div>
      </button>
      -->
      <mat-menu #appsmenu="matMenu" class="apps-dd cardWithShadow">
        <!--
          <div class="row">
            <div class="col-sm-8 b-r-1 p-r-0">
            <div class="p-32 p-b-0">
              <div class="row">
                <div class="col-sm-6 text-hover-primary" *ngFor="let appdd of apps">
                  <a [routerLink]="[appdd.link]" class="d-flex align-items-center text-decoration-none m-b-24">
                    <span
                      class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center">
                      <img [src]="appdd.img" width="20" />
                    </span>
  
                    <div class="m-l-16">
                      <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                        {{ appdd.title }}
                      </h5>
                      <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="b-t-1 p-24 d-none d-lg-flex align-items-center justify-content-between">
              <span class="d-flex align-items-center mat-subtitle-1 f-s-16 f-w-500">
                <i-tabler name="help" class="icon-20 m-r-8"></i-tabler>Frequently
                Asked Questions
              </span>
              <a [routerLink]="['/theme-pages/faq']" mat-flat-button color="primary">Check</a>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="p-x-16 p-y-32">
              <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">Quick Links</h4>
              <div class="text-hover-primary" *ngFor="let quicklink of quicklinks">
                <a [routerLink]="[quicklink.link]"
                  class="hover-text text-decoration-none mat-body-1 f-w-600 d-block p-y-8">{{ quicklink.title }}</a>
              </div>
            </div>
          </div>
        </div>
        -->
      </mat-menu>
      <!-- 
      <a mat-button [routerLink]="['/apps/chat']">Chat</a>
      <a mat-button [routerLink]="['/apps/calendar']">Calendar</a>
      <a mat-button [routerLink]="['/apps/email/inbox']">Email</a>
       -->
    </div>

    <span class="flex-1-auto"></span>

    <!-- Mobile Menu 
      <button mat-icon-button (click)="toggleMobileFilterNav.emit()" class="d-flex d-lg-none justify-content-center">
      <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>
    </button>
    -->

    <!-- --------------------------------------------------------------- -->
    <!-- langugage Dropdown 
      <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
      <img [src]="selectedLanguage.icon" class="rounded-circle object-cover icon-20" />
    </button>
    <mat-menu #flags="matMenu" class="cardWithShadow">
      <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
        <div class="d-flex align-items-center">
          <img [src]="lang.icon" class="rounded-circle object-cover m-r-8 icon-20" />
          <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
        </div>
      </button>
    </mat-menu>
    -->
    <!-- --------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------- -->
    <!--
      <button mat-icon-button [matMenuTriggerFor]="notificationmenu" aria-label="Notifications">
      <i-tabler class="d-flex" name="bell" matBadge="1" matBadgeColor="primary"></i-tabler>
    </button>
    <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
      <div class="d-flex align-items-center p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0">Notifications</h6>
        <span class="m-l-auto">
          <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">5 new</span>
        </span>
      </div>
      <button mat-menu-item class="p-x-32 p-y-16" *ngFor="let notification of notifications">
        <div class="d-flex align-items-center">
          <img [src]="notification.img" class="rounded-circle" width="48" />
          <div class="m-l-16">
            <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
              {{ notification.title }}
            </h5>
            <span>{{ notification.subtitle }}</span>
          </div>
        </div>
      </button>
      <div class="p-y-12 p-x-32">
        <button mat-stroked-button color="primary" class="w-100">
          See all notifications
        </button>
      </div>
    </mat-menu>
    -->

    <!-- --------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------- -->
    <!-- profile Dropdown -->
    <!-- --------------------------------------------------------------- -->
    <button mat-icon-button [matMenuTriggerFor]="profilemenu" aria-label="Notifications">
      <img *ngIf="avatar; else avatarTemplate" [src]="avatar" class="rounded-circle object-cover" width="36"
        height="36" />
      <ng-template #avatarTemplate>
        <img #avatarTemplate [src]="avatarDefault" class="rounded-circle object-cover" width="36" height="36" />
      </ng-template>
    </button>
    <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
      <ng-scrollbar class="position-relative" style="height: 500px"> <!-- 647px -->
        <div class="p-x-32 p-y-16">
          <h6 class="f-s-16 f-w-600 m-0">User Profile</h6>
          <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
            <img *ngIf="avatar; else avatarTemplate1" [src]="avatar" class="rounded-circle object-cover" width="96"
              height="96" />
            <ng-template #avatarTemplate1>
              <img #avatarTemplate1 [src]="avatarDefault" class="rounded-circle object-cover" width="96" height="96" />
            </ng-template>
            <div class="m-l-16">
              <h6 class="f-s-14 f-w-600 m-0"> {{ user.firstName }} {{ user.lastName }} </h6>
              <span class="f-s-14 d-block m-b-4"> {{ user.role.name }} </span>
              <span class="d-flex align-items-center">
                {{user.email }}
              </span>
            </div>
          </div>
        </div>

        <div class="p-x-32">
          <a class="p-y-16 text-decoration-none d-block text-hover-primary" [routerLink]="['/setting/account']"
            *ngFor="let profile of profiledd">
            <div class="d-flex align-items-center">
              <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                <img [src]="profile.img" width="20" />
              </button>

              <div class="m-l-16">
                <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                  {{ profile.title }}
                </h5>
                <span class="mat-body-1">{{ profile.subtitle }}</span>
              </div>
            </div>
          </a>

          <!-- upgrade -->
          <div *ngIf="isUpgrade" class="p-24 overflow-hidden bg-light-primary rounded position-relative m-y-16">
            <div class="d-flex align-items-center">
              <div>
                <h5 class="f-s-18 m-0 f-w-600 m-b-12">
                  Unlimited <br />Access
                </h5>
                <button mat-flat-button color="primary" [routerLink]="['/billing/pricing']">Upgrade</button>
              </div>
              <div class="m-l-auto">
                <img src="/assets/images/backgrounds/unlimited-bg.png" alt="upgrade-bg" class="upgrade-bg" />
              </div>
            </div>
          </div>
          <!--/ upgrade -->

          <!-- Premium -->
          <div *ngIf="!isUpgrade" class="p-24 overflow-hidden bg-light-primary rounded position-relative m-y-16">
            <div class="d-flex align-items-center">
              <div>
                <h5 class="f-s-18 m-0 f-w-600 m-b-12 text-primary">
                  Premium <br />Member
                </h5>
              </div>
              <div class="m-l-auto">
                <img src="/assets/images/backgrounds/unlimited-bg.png" alt="upgrade-bg" class="upgrade-bg" />
              </div>
            </div>
          </div>
          <!--/ Premium -->
        </div>

        <div class="p-y-12 p-x-32">
          <a (click)="logout()" mat-stroked-button color="primary" class="w-100">Logout</a>
        </div>
      </ng-scrollbar>
    </mat-menu>
  </mat-toolbar>
</div>