import { CommonModule, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TablerIconsModule } from 'angular-tabler-icons';
import { Subject, takeUntil } from 'rxjs';
import { MaterialModule } from 'src/app/material.module';
import { LoginService } from 'src/app/pages/authentication/services/login.service';
import { Organization } from 'src/app/pages/organization/models/organization';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trial-banner',
  templateUrl: './trial-banner.component.html',
  styleUrls: ['./trial-banner.component.scss'],
  standalone: true,
  imports: [RouterModule, CommonModule, MaterialModule, TablerIconsModule, NgIf]
})
export class TrialBannerComponent implements OnInit, OnDestroy {
  showBanner: boolean = false;
  daysLeft: number = 0;
  unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
    this.getDaysLeft();
  }

  getDaysLeft() {
    this.loginService.currentOrganization$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (organization: Organization) => {
          if (organization) {
            const diffTime = new Date(organization.trialEnd).getTime() - new Date().getTime();
            this.daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            this.showBanner = this.daysLeft > 0 && organization.subscription.plan === environment.trialPlanId;
          } else {
            this.daysLeft = 0;
            this.showBanner = false;
          }
        },
      });
  }

  dismissBanner() {
    this.showBanner = false;
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
