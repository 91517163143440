export interface AppSettings {
  dir: 'ltr' | 'rtl';
  theme: string;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  boxed: boolean;
  horizontal: boolean;
  activeTheme: string;
  language: string;
  cardBorder: boolean;
  navPos: 'side' | 'top';
  appName: string;
  userDataLocalStorageKey: string;
  organizationIdLocalStorageKey: string;
  ownerDetails: {
    name: string;
    email: string;
    phone: string;
    address: string;
  };
  primaryColor: string;
  defaultAvatar: string;
  defaultOrgLogo: string;
  defaultTenantLogo: string;
  imageSize: string;
}

export const defaults: AppSettings = {
  dir: 'ltr',
  theme: 'light',
  sidenavOpened: false,
  sidenavCollapsed: false,
  boxed: true,
  horizontal: false,
  cardBorder: false,
  activeTheme: 'blue_theme',
  language: 'en-us',
  navPos: 'side',
  appName: 'Mgeni',
  userDataLocalStorageKey: 'user_data',
  organizationIdLocalStorageKey: 'organization_id',
  ownerDetails: {
    name: 'Securex Agencies Ltd',
    email: 'info@securex.co.ke',
    phone: ' +254 711 069 999',
    address: '9 Riverside Drive, Nairobi, Kenya'
  },
  primaryColor: '#5d87ff',
  defaultAvatar: '/assets/images/profile/default.png',
  defaultOrgLogo: '/assets/images/backgrounds/silver.png',
  defaultTenantLogo: '/assets/images/logos/tenant_logo.png',
  imageSize: '5 MB'
};
